<template>
  <div class="login_right_form">
    <div class="login_title">欢迎登录</div>
    <el-form ref="form" :model="form" class="login_right_form_inner">
      <el-form-item>
        <el-input v-model="form.account" placeholder="请输入账号/用户名" class="username">
          <i slot="prefix">
            <img src="../../assets/image/login/user.png" width="17.2px" height="19.6px"></img>
            </i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input type='password' v-model="form.password" placeholder="请输入密码" class="password">
          <i slot="prefix">
            <img src="../../assets/image/login/password.png" width="17.2px" height="19.6px"></img>
            </i>
        </el-input>
      </el-form-item>
      <div class="submit_opration">
        <el-button class="submit_btn" type="primary" @click="login">登录</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import {loginFn} from '@/api/login.js'
export default {
  name:'login',
  data(){
    return{
      form: {
        account: '',
        password:''
      },
    }
  },
  methods:{
    //登录
    login(){
      let self = this
      loginFn(self.form).then(res => {
        if (res.code == 200){
          localStorage.setItem('token1',res.datas.api_token)
          localStorage.removeItem('defaultActive1')
          this.$router.push('/home')
        }
      })
    },
  }
}
</script>
<style scoped lang='scss'>
.login_right_form{
    width: 100%;
    margin-top: 300px;
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    .login_title{
      color:#000;
      font-size: 23px;
      // margin-left: 192px;
      text-align: center;
      margin-top: 30px;
      font-weight: 700;
    }
    .login_right_form_inner{
      width: 300px;
      // margin-left: 40px;
      margin-top: 25px;
      // .username{.login_right_form .el-input__inner
      //   margin-top: 50px;
      // }
      // .username >>>.el-input__inner{
      //   height: 51px !important;
      //   border-radius: 8px !important;
      //   font-size: 14px;
      //   padding-left: 58px;
      // }
      // .username >>>.el-input__prefix{
      //   padding-top: 50px !important;
      //   padding-left: 30px !important;
      // }
      // .password >>>.el-input__inner{
      //   height: 51px !important;
      //   border-radius: 8px !important;
      //   font-size: 14px;
      //   padding-left: 58px;
      // }
      // .password >>>.el-input__prefix{
      //   padding-top: 1px !important;
      //   padding-left: 30px !important;
      // }
    }
    .submit_opration{
      margin-top: 35px;
      width: 100%;
      cursor: pointer;
      .submit_btn{
        width: 100%;
        font-size: 16px;
        background-color: #4278E1;
        font-weight: 500;
        
      }
    }
  }
</style>
